<template>
  <page>
    <module enable-flex>
      <m-search ref="search" @search="searchHandle">
        <m-search-item label="商品类目名称">
          <c-input name="type_name"></c-input>
        </m-search-item>
      </m-search>

      <m-operate>
        <c-button v-if="auth('b_product_type_edit')" color="sys" @click="createProductType()">新建商品类目</c-button>
      </m-operate>

      <c-table ref="table" height="grow">

        <c-table-column
            label="商品类目名称"
            width="200"
            name="type_name"
        ></c-table-column>

	    <c-table-column
		      type="sort"
		      label="排序"
		      @sort="sortHandle"
	    ></c-table-column>

        <c-table-column
            label="备注"
            width="300"
            name="remark"
        >
          <template #default="{data}">
            <span v-if="data.remark">{{data.remark}}</span>
            <span v-else>--</span>
          </template>
        </c-table-column>

		<c-table-column
		    label="创建时间"
		    width="140"
		    name="create_date"
		></c-table-column>

        <c-table-column
            type="button"
            label="操作"
            width="180"
        >
          <template v-slot="{data}">
            <c-table-button v-if="auth('b_product_type_edit')" @click="editProductType(data)">编辑</c-table-button>
			<c-table-button v-if="auth('b_product_type_edit')" @click="deleteProductType(data)">删除</c-table-button>
			<c-table-button v-if="auth('b_product_type_params')" @click="setProductTypeParams(data)">配置属性</c-table-button>
          </template>
        </c-table-column>
      </c-table>
    </module>

    <c-dialog ref="productTypeEdit" :title="editState ? '编辑商品类目' : '新建商品类目'" width="600" @resolve="submit">
      <c-form ref="productTypeForm">
        <c-form-item label="商品类目名称" required>
          <c-input name="type_name"></c-input>
        </c-form-item>
        <c-form-item label="备注">
	        <c-input type="textarea" name="remark"></c-input>
        </c-form-item>
        <c-input type="hidden" name="id"></c-input>
      </c-form>
    </c-dialog>
	<!--关联属性列表 -->
	<c-dialog ref="productTypeParamsDialog" :title="this.product_type_name+' - 属性信息'" width="950">
		  <m-search ref="productParamsSearch" @search="searchCustomerHandle">
			  <m-search-item label="属性名称">
				  <c-input name="parameter_name"></c-input>
			  </m-search-item>
			  <m-search-item label="属性类型">
				  <c-select name="parameter_type" :options="paramTypeOptions"></c-select>
			  </m-search-item>
		  </m-search>
		  <m-operate>
			  <c-button v-if="auth('b_product_type_params')" color="sys" @click="paramAdd()">新增属性</c-button>
		  </m-operate>
		  <c-table ref="productParamsTable" height="400">
			  <c-table-column
				  label="属性名称"
				  name="parameter_name"
				  width="150"
			  ></c-table-column>

			  <c-table-column
				  label="是否必填"
				  name="required_flag"
				  width="80"
			  >
				  <template v-slot="{data}">
					  {{requiredFlag[data.required_flag]}}
				  </template>
			  </c-table-column>

			  <c-table-column
				  label="属性类型"
				  name="parameter_type"
				  width="80"
			  >
				  <template v-slot="{data}">
					  {{paramTypeMap[data.parameter_type]}}
				  </template>
			  </c-table-column>

			  <c-table-column
				  label="属性可选值"
				  name="parameter_values"
				  width="300"
			  ></c-table-column>

			  <c-table-column
				  type="sort"
				  label="排序"
				  power="b_product_param_sort"
				  @sort="paramSortHandle"
			  ></c-table-column>

			  <c-table-column
				  type="button"
				  fixed="right"
				  label="操作"
				  width="120"
			  >
				  <template v-slot="{data}">
					  <c-table-button v-if="auth('b_product_type_params')" @click="paramEdit(data)">编辑</c-table-button>
					  <c-table-button v-if="auth('b_product_type_params')" @click="paramDel(data)">删除</c-table-button>
				  </template>
			  </c-table-column>
		  </c-table>
	  </c-dialog>
	<c-dialog ref="editDialog" :title="paramEditState ? '编辑属性' : '新增属性'" width="600" @resolve="paramSubmit">
		  <c-form ref="editForm" unit-width="100%">
			<c-form-item label="属性名称" required>
				<c-input name="parameter_name"></c-input>
				<c-input type="hidden" name="id"></c-input>
			</c-form-item>
			<c-form-item label="是否必填" required>
				<c-radio-group  name="required_flag" :options="requiredFlagState"></c-radio-group>
			</c-form-item>
			<c-form-item label="属性类型" required>
				<c-select v-model="paramType" name="parameter_type" :options="paramTypeOptions"></c-select>
			</c-form-item>
			<c-form-item label="属性可选值" v-show="paramType != 'text'" required>
				<c-input type="textarea" name="parameter_values" placeholder="多个选项请使用英文逗号分隔" maxlength="1000"></c-input>
			</c-form-item>
		 </c-form>
	</c-dialog>
  </page>
</template>

<script>
export default {
  name: 'm_product_type',
  components: {
  },
  data() {
    return {
      editState: false,
	  paramEditState: false,
	  product_type_name:'',
	  product_type_id:'',
      paramType: '',
      paramTypeOptions: [{
		    name: '文本框',
		    value: 'text'
	  }, {
		    name: '下拉框',
		    value: 'select'
	  }, {
		    name: '单选框',
		    value: 'radio'
	  }, {
		    name: '复选框',
		    value: 'checkbox'
	  }],
	  requiredFlagState: [{
	    name: '不必填',
	    value: '1'
	  }, {
		name: '必填',
		value: '2'
	  }],
	  paramTypeMap:{'text': '文本框','select': '下拉框','radio': '单选框','checkbox': '复选框'},
	  requiredFlag:{'1': '不必填','2': '必填'}
    }
  },
  computed: {
  },
  mounted() {
    this.$refs.search.search();
  },
  methods: {
    tabHandle(item) {
      this.$refs.search.search();
    },
    searchHandle(data) {
      this.$refs.table.load({
        url: '/product/type/list',
        data
      });
    },
    //创建商品类目
    createProductType() {
      this.editState = false;
      this.$refs.productTypeForm.clear();
      this.$refs.productTypeForm.set({});
      this.$refs.productTypeEdit.open();
    },
    //编辑商品类目
    editProductType(val) {
      this.editState = true;
      this.$refs.productTypeForm.set({
	      id:val.id,
	      type_name:val.type_name,
	      remark:val.remark||''
      });
      this.$refs.productTypeEdit.open();
    },
	//删除商品类目
	deleteProductType(val){
		this.$confirm({
		  title: '确认',
		  message: '确定要删除商品类目【'+val.type_name+'】吗？',  // 提示内容,
		  buttonName: '确定',  // 确定按钮文字
		  cancelButton: true,  // 是否显示取消按钮
		  resolve: () => {
		    this.request({
		      url: '/product/type/delete',
		      data: {id:val.id},
		      loading: true,
		      success: data => {
		        this.$message({
		          message: '删除成功',
		          type: 'success'
		        });
				this.$refs.table.update();
		      }
		    });
		  },  // 确定按钮回调
		  cancel: () => {},  // 取消按钮回调
		});
	},
    //提交保存
    submit(stop) {
      stop();
      this.$refs.productTypeForm.submit({
        url: this.editState ? '/product/type/update' : '/product/type/add',
        rule: {
	        type_name: '请填写商品类目名'
        },
        dataFormatter: data => {
          return data;
        },
        success: (data, res) => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$refs.table.update();
          this.$refs.productTypeEdit.close();
        }
      });
    },
	//排序
	sortHandle(row, type, next) {
		  let submitData ={};
		  submitData.type = type;
		  if(type=='top' || type=='bottom'){
			  submitData.id = row.data.id;
		  }
		  if(type=='up'){
			  submitData.id1 = row.data.id;
			  submitData.id2 = row.prev().data.id;
			  submitData.type = "";
		  }
		  if(type=='down'){
			  submitData.id1 = row.data.id;
			  submitData.id2 = row.next().data.id;
			  submitData.type = "";
		  }
		  this.request({
			  url: '/product/type/sort',
			  data: submitData,
			  loading: true,
			  success: data => {
				  next();
			  }
		  });
	 },
     //打开关联客户弹窗
	 setProductTypeParams(data){
	  this.$refs.productTypeParamsDialog.open();
	  this.product_type_id = data.id;
	  this.product_type_name = data.type_name;
	  this.$refs.productParamsSearch.search();
    },
    //搜索已有属性
    searchCustomerHandle(data){
	  data.product_type_id = this.product_type_id;
	  this.$refs.productParamsTable.load({
		  url: '/product_param/list',
		  data
	  });
    },
	paramAdd() {
		  this.paramEditState = false;
		  this.$refs.editForm.clear();
		  this.$refs.editForm.set({parameter_type:'text'});
		  this.$refs.editDialog.open();
	},
	  paramEdit(val) {
		  this.paramEditState = true;
		  this.$refs.editForm.set(val);
		  this.$refs.editDialog.open();
	  },
	  paramDel(val) {
		  this.$confirm({
			  title: '确认删除？',  // 提示内容,
			  message: '确定要删除商品属性【'+val.parameter_name+'】吗？',  // 提示内容,
			  buttonName: '确定',  // 确定按钮文字
			  cancelButton: true,  // 是否显示取消按钮
			  resolve: () => {
				  this.request({
					  url: '/product_param/remove',
					  data: {id:val.id},
					  loading: true,
					  success: data => {
						  this.$message({
							  message: '操作成功',
							  icon: 'success'
						  });
						  this.$refs.productParamsTable.update();
					  }
				  });
			  },  // 确定按钮回调
			  cancel: () => {},  // 取消按钮回调
		  });
	  },
	  paramSortHandle(row, type, next) {
		  //row.prev() 上一行数据
		  //row.next() 下一行数据
		  let submitData ={};
		  submitData.type = 1
		  if(type=='top' || type=='bottom'){
			  submitData.sort_type = type;
			  submitData.id = row.data.id;
		  }
		  if(type=='up'){
			  submitData.id1 = row.data.id;
			  submitData.id2 = row.prev().data.id;
		  }
		  if(type=='down'){
			  submitData.id1 = row.data.id;
			  submitData.id2 = row.next().data.id;
		  }
		  this.request({
			  url: '/product_param/sort',
			  data: submitData,
			  loading: true,
			  success: data => {
				  next();
			  }
		  });
	  },
	  paramSubmit(stop) {
		  stop();
		  this.$refs.editForm.submit({
			  url: this.paramEditState ? '/product_param/edit' : '/product_param/add',
			  rule: {
				  parameter_name: '请填写属性名称',
				  required_flag: '请选择属性是否必填',
				  parameter_type: '请选择属性类型',
				  parameter_values: [{
					  type: 'empty',
					  message: '请填写属性可选值',
					  skip: (value,data) => {
						  console.log('data.parameter_type==='+data.parameter_type);
						  return data.parameter_type == 'text';
					  }
				  }]
			  },
			  dataFormatter: data => {
				  data.product_type_id = this.product_type_id;
				  data.parameter_values = data.parameter_values.replace(/\r\n|\r|\n/g, '').replace(/^,/, '').replace(/,$/, '');
				  return data;
			  },
			  success: (data, res) => {
				  this.$message({
					  message: '保存成功'
				  });
				  this.$refs.productParamsTable.update();
				  this.$refs.editDialog.close();
			  }
		  });
	  }
  }
}
</script>